$(".nav__item").hover(onIn, onOut);
$(".nav__item-show").click(function() {
    if ((window.innerWidth < 993)) {
        $(this).parents(".nav__item").toggleClass("nav__item--active")
        $(this).siblings(".nav__item-hidden").slideToggle()
    }
})

function onIn() {
    if (window.innerWidth > 1200) {
        let el = $(this)
        setTimeout(function() {
            if (el.is(':hover')) {
                el.addClass("nav__item--active")
            }
        }, 200);
    }
}

function onOut() {
    if (window.innerWidth > 1200) {
        $(this).removeClass("nav__item--active")
    }
}

$(".burger").click(function () {
    $(".header").toggleClass("_open")
    $("body").toggleClass("fixed-body")
})
