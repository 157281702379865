$("._tab").click(function() {
    let parentBlock = $(this).parents(".tabs-parent")
    let tabId = $(this).attr("data-tab")
    $(parentBlock).find("._tab").removeClass("_active")
    $(this).addClass("_active")
    $(parentBlock).find("._tab-content").removeClass("_active")
    $(parentBlock).find(`._tab-${tabId}`).addClass("_active")

    setCurrentTab(parentBlock)

    $(".tabs").removeClass("_active")
})


function setCurrentTab(parent) {
    let activeTab = $(parent).find("._tab._active span").text()
    $(parent).find(".tabs__current-title").html(activeTab)
}

$(".tabs-parent").each(function (i,el) {
    setCurrentTab(el)
})

$(".tabs__current").click(function () {
    $(this).parents(".tabs").toggleClass("_active")
})

$(document).mouseup(function(e) {
    var div = $('.tabs');
    if (!div.is(e.target) && div.has(e.target).length === 0) {
        if($(div).hasClass("_active")){
            $(div).removeClass("_active")
        }
    }
});

$(".fg input").each(function(index, el) {
    InputIsFilled($(el))
});

$(".fg textarea").each(function(index, el) {
    InputIsFilled($(el))
});

$(".fg input").change(function() {
    InputIsFilled($(this))
})

$(".fg textarea").change(function() {
    InputIsFilled($(this))
})

function InputIsFilled(el) {
    if ($(el).val()) {
        $(el).addClass("input-active")
    } else {
        $(el).removeClass("input-active")
    }
}
