function mainSlider() {
    var swiper = new Swiper('.main .swiper-container', {
        slidesPerView: 1,
        spaceBetween: 0,
        autoplay: true,
        loop: true,
        speed: 1000,
        navigation: {
            nextEl: '.main .swiper-button-next',
            prevEl: '.main .swiper-button-prev',
        },
    })
}

function projectsSlider() {
    var swiper = new Swiper('.projects .swiper-container', {
        slidesPerView: 1,
        spaceBetween: 0,
        autoplay: true,
        loop: true,
        speed: 1000,
        navigation: {
            nextEl: '.projects .swiper-button-next',
            prevEl: '.projects .swiper-button-prev',
        },
        pagination: {
            el: '.projects .swiper-pagination',
            type: 'bullets',
            clickable: true,
        },
    })
}

function interiorSlider() {
    var swiper = new Swiper('.interior .swiper-container', {
        slidesPerView: "auto",
        spaceBetween: 0,
    })
}

function interior3Slider() {
    var swiper = new Swiper('.interior3 .swiper-container', {
        slidesPerView: 3,
        spaceBetween: 0,
    })
}

let hitsSlidersArray = []

function hitsSliders() {
    $(".hits").each(function(index, el) {
        $(el).attr("data-slider-id", `${index}`)
        hitsSlidersArray[index] = new Swiper(`.hits[data-slider-id="${index}"] .swiper-container`, {
            slidesPerView: 3,
            spaceBetween: 0,
            navigation: {
                nextEl: `.hits[data-slider-id="${index}"] .swiper-button-next`,
                prevEl: `.hits[data-slider-id="${index}"] .swiper-button-prev`,
            },
        })
    })
}

mainSlider()
projectsSlider()
interiorSlider()
interior3Slider()
hitsSliders()
